<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb>
            <breadcrumb-item>
                <router-link :to="{path:'/admin/users'}"  class="color-blue-var">Propiedad</router-link>
            </breadcrumb-item>
            <breadcrumb-item>
                <router-link :to="{path:'/admin/users'}" class="color-blue-var" >{{$t('user.index.title')}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active>
                {{info_page.header}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2"  v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">
        <div>
            <form>
                <div class="row">
                    <vue-topprogress ref="topProgress2"></vue-topprogress>
                    <div class="col-md-6">
                        <fg-input type="email"
                                  :name="$t('form.email')"
                                  :label="$t('form.email')"
                                  :placeholder="$t('form.email')"
                                  v-validate="modelValidations.email"
                                  :error="getError($t('form.email'))"
                                  v-model="user.email">
                        </fg-input>
                    </div>
                    <div class="col-md-6 custom-error">
                        <fg-input type="password"
                                  class="mb-0"
                                  :name="$t('form.password')"
                                  :label="$t('form.password')"
                                  :placeholder="$t('form.password')"
                                  @input="customValidity"
                                  v-model="user.password">
                        </fg-input>

                        <label class="text-danger "  v-show="errors_custom.password.status">{{errors_custom.password.text}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :name="$t('form.name')"
                                  :label="$t('form.name')"
                                  :placeholder="$t('form.name')"
                                  v-validate="modelValidations.first_name"
                                  :error="getError($t('form.name'))"
                                  v-model="user.first_name">
                        </fg-input>
                    </div>
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :name="$t('form.lastname')"
                                  :label="$t('form.lastname')"
                                  :placeholder="$t('form.lastname')"
                                  v-validate="modelValidations.last_name"
                                  :error="getError($t('form.lastname'))"
                                  v-model="user.last_name">
                        </fg-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label>{{$t('form.pre_phone')}}</label>


                            <fg-input type="text"
                                      :name="$t('form.lada')"
                                      v-validate="modelValidations.lada"
                                      :error="getError($t('form.lada'))"
                                      v-model="user.lada">
                                <el-select class="select-default" size="large" :placeholder="$t('form.pre_phone')"
                                           v-model="user.lada" :name="$t('form.lada')">
                                    <el-option v-for="option in lada.ladas" class="select-default" :value="option.label"
                                               :label="option.label" :key="option.label">
                                    </el-option>
                                </el-select>


                            </fg-input>



                        </div>

                    </div>
                    <div class="col-md-8">
                        <fg-input type="text"
                                  :name="$t('form.phone_fijo')"
                                  :label="$t('form.phone_fijo')"
                                  :placeholder="$t('form.phone_fijo')"
                                  v-validate="modelValidations.phone"
                                  :error="getError($t('form.phone_fijo'))"
                                  v-model="user.phone">
                        </fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('form.pre_cellphone')}}</label>
                            <fg-input type="text"
                                      :name="$t('form.pre_cellphone')"
                                      v-validate="modelValidations.lada_cell"
                                      :error="getError($t('form.pre_cellphone'))"
                                      v-model="user.lada_cell">
                                      <el-select class="select-default" size="large" :placeholder="$t('form.pre_cellphone')"
                                       v-model="user.lada_cell">
                                <el-option v-for="option in lada.ladas" class="select-default" :value="option.label"
                                           :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>
                            </fg-input>
                           
                        </div>
                    </div>
                    <div class="col-md-8">
                        <fg-input type="text"
                                  :name="$t('form.phone_movil')"
                                  :label="$t('form.phone_movil')"
                                  :placeholder="$t('form.phone_movil')"
                                  v-validate="modelValidations.cellphone"
                                  :error="getError($t('form.phone_movil'))"
                                  v-model="user.cellphone">
                        </fg-input>
                    </div>
                </div>
                <div class="row" v-if="currentUser.is_admin">
                    <div class="col-md-6">
                        <div class="form-check">
                            <label for="is_admin" class="form-check-label check-admin">
                                <input class="form-check-input" type="checkbox" id="is_admin" v-model="user.is_admin" @click="checkisAdmin()">
                                <span class="form-check-sign" ></span> Usuario administrador
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>
                <!--<div class="row pl-4 pr-4" >-->
                    <div class="row pl-4 pr-4" v-show="!user.is_admin && currentUser.is_admin">
                    <div class="col-md-12">
                        <label class="title-section">{{$t('user.roles.title')}}</label>
                    </div>

                    <div class="container-roles" v-for="(property, n) in info_page.properties">
                        <div class="row">
                            <div class="col-md-6 pl-0">
                                <div class="form-group has-label has-error">
                                    <label >{{$t('property.index.property')}}</label>
                                    <el-select class="select-default" size="large" :placeholder="$t('property.index.property')"
                                               v-model="property.property" @change="checkDifference()">
                                        <el-option v-for="item in info_page.properties_all" class="select-default" :value="item._id" v-show="item.status===true"
                                                   :label="item.name" :key="item._id">
                                        </el-option>
                                    </el-select>

                                    <div class="custom-error" v-if="errors_custom.property.status && property.property === ''">
                                        <label class="text-danger" >{{errors_custom.property.text_required}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6">
                                <a class="item-minus" v-tooltip.bottom="'Eliminar propiedad'">
                                    <i class="fa fa-minus" @click="deleteProperty(n)" v-if="info_page.properties_all.length>1 && n !== 0"></i>
                                </a>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-4 item-rol" v-for="(rol, idx) in property.roles">
                                    <div class="box-inside">
                                        <label>{{info_page.roles.name[idx]}}</label>
                                        <a>
                                            <span :class="checkStatus(rol, 1)"   v-on:click="changeRol(1, n, idx)" v-tooltip.bottom="'Lectura'"></span>

                                            <span :class="checkStatus(rol, 2)"   v-on:click="changeRol(2, n, idx)" v-tooltip.bottom="'Lectura y escritura'"></span>
                                           <!--<span class="fa fa-pencil"  @click.prevent="changeRol(2, n, idx)" :class="(info_page.properties[n].roles[idx]===2)?'marked':''"></span>-->
                                        </a>
                                    </div>
                            </div>
                            <div class="col-12">
                                <div class="custom-error" v-if="errors_custom.property.status  && property.roles.every(item=> item === 0)">
                                    <label class="text-danger" >{{errors_custom.property.text}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="custom-error" v-if="errors_custom.property.status && info_page.properties.length===0">
                                    <label class="text-danger" >{{errors_custom.property.text_required}}</label>
                                </div>

                            </div>
                        </div>
                    <div class="container-footer">
                        <div class="row">
                            <div class="col-md-12">
                                <a class="btn-plus"  v-show="info_page.properties_all.length > info_page.properties.length">
                                    <i class="fa fa-plus" @click="addProperty()" v-tooltip.bottom="'Agregar propiedad'"></i><!---->
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-3 mb-3">
                    <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                        {{info_page.button}}
                    </button>

                    <button type="button" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: ruta})">
                        {{$t('form.cancel')}}
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import Settings from "src/js/helpers/Settings";
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';


    let settings = new Settings();
    import { vueTopprogress } from 'vue-top-progress';
    import EventBus from "src/js/helpers/EventBus";
    import moment from 'moment';

    const propertyService = new PropertyService();
    const logFunctions = new LogFunctions();
    const logService = new LogService();


    import { mapGetters } from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    export default {
        computed: {
        ...mapGetters(["currentUser", "selected_property","permissions"]),
      },
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Option.name]: Option,
            [Select.name]: Select,
            vueTopprogress
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                screenLoading: false,
                screenText: '',
                info_page:{
                    original_info:{},
                    section_name: 'user',
                    action: 'add',
                    header: this.$t('user.new.header'),
                    button:  this.$t('user.new.header'),
                    properties_all:[

                    ],
                    properties: [
                        {
                            permission_id: '',
                            property:'',
                            roles:[0,0,0,0,0,0],
                        }
                    ],
                    properties_old:[],

                    roles:{
                        name: [
                            this.$t('user.roles.rooms'),
                            this.$t('user.roles.reservations'),
                            this.$t('user.roles.marketing'),
                            this.$t('user.roles.revenue'),
                            this.$t('user.roles.accounting'),
                            this.$t('user.roles.properties'),
                        ],
                        values: [0,0,0,0,0,0]
                    }
                },
                ruta: 'Properties',
                user: {
                    _id: '',
                    email: '',
                    is_admin: false,
                    first_name: '',
                    last_name: '',
                    lada: '',
                    phone: '',
                    lada_cell: '',
                    cellphone: '',
                    password: '',
                   },

                errors_custom:{
                    password: {
                        required: true,
                        min: 6,
                        status: false,
                        text: ''
                    },
                    property:{
                        status: false,
                        text: '',
                        text_required: ''
                    }
                },

                modelValidations: {
                    email: {
                        required: true,
                        email: true,
                    },
                    first_name: {
                        required: true
                    },
                    last_name: {
                        required: true
                    },
                    lada: {
                        required: true,
                        min: 3
                    },
                    phone: {
                        required: true,
                        min: 10
                    },
                    lada_cell: {
                        required: true,
                        min: 3
                    },
                    cellphone: {
                        required: true,
                        min: 10
                    }
                },

                lada: {
                    selected:1,
                    ladas:[
                        {
                            label: '+52',
                            value: 1
                        },
                        {
                            label: '+01',
                            value: 2
                        },
                    ]
                },
                checkboxes: {
                    first: false,
                    second: false,
                    a: false,
                    b: false,
                    c: false,
                    unchecked: false,
                    checked: true,
                    disabledUnchecked: false,
                    disabledChecked: true
                }
            }
        },
        created(){
          this.initPage();
        },
        methods: {
            initPage(){
                this.initLoading()
                if(this.currentUser.is_admin){
                  this.ruta = 'Users'
                };

                this.configLogs(null, this.info_page.section_name);
                propertyService.getProperties().then(response => {

                        response.data.data.properties.forEach(async tnmt => {
                            this.info_page.properties_all.push({
                                _id:tnmt._id,
                                name: tnmt.name,
                                logo: tnmt.logo,
                                status: true,
                            });
                        });
                    if(this.$route.name==='editUser'){
                        this.errors_custom.password.required = false;
                        this.info_page.action = 'edit';
                        this.log_page.action = 1;
                        this.info_page.header = this.$t('user.edit.header');
                        this.info_page.button = this.$t('user.edit.button');
                        let user_id = this.$route.params.id;
                        this.updateData(user_id);
                    }else{
                        this.closeLoading();
                    }
                });

            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },

             customValidity(){

                 //console.log('checkDifference');

                //let max_children = parseInt(this.room.max_children);
                let errors_custom = this.errors_custom;
                errors_custom.password.status = false;
                let error_response = true;
                let user = this.user;
                 errors_custom.property.status = false;
                 console.log('start validity', user)


                if(user.password.length>0 && user.password.length< errors_custom.password.min){
                    error_response = false;
                    errors_custom.password.status = true;
                    errors_custom.password.text = 'El campo Contraseña debe tener al menos 6 caracteres';
                }


                if(user.password.trim().length===0 && errors_custom.password.required){
                    error_response = false;
                    errors_custom.password.status = true;
                    errors_custom.password.text =  'El campo Contraseña es obligatorio';

                }
                //console.log('user', user);
                if(!user.is_admin){
                    //console.log('no es admin');
                    if(this.info_page.properties.length === 0){
                        //console.log('sin propiedades');
                        errors_custom.property.status = true;
                        errors_custom.property.text =  'Es obligatorio agregar una propiedad';
                        error_response = false;
                    }else{
                        this.info_page.properties.forEach(property=>{
                            //console.log('propiedad', property);
                            if(property.property===''){
                                errors_custom.property.status = true;
                                errors_custom.property.text_required =  'El campo propiedad es obligatorio';
                                error_response = false;
                            }
                            if(property.roles.every(item => item === 0)){
                                errors_custom.property.status = true;
                                errors_custom.property.text =  'Es necesario seleccionar al menos un permiso';
                                error_response = false;
                            }
                        })
                    }



                }






                return error_response;
            },

            updateData(user_id) {



                propertyService.getUserId(user_id).then(response => {

                    let user = response.data.data.user;

                    for(const idx in user ) {
                        this.user[idx] = user[idx];
                    }

                    this.info_page.original_info = JSON.parse(JSON.stringify(user))

                    //this.user = user;
                    user.password = '';
                    this.info_page.properties.splice(0, 1);
                    user.permissions.forEach((item, idx) => {
                        this.info_page.properties_old.push(item._id);
                        this.info_page.properties.push({
                            permission_id:item._id,
                            property:item.property,
                            roles:item.sections,
                        })
                    })

                       //console.log('user', user)
                }).finally(()=>{

                    this.closeLoading();
                });
            },
            addProperty(){
              this.info_page.properties.push({
                  permission_id:'',
                  property:'',
                  roles:[0,0,0,0,0,0],

              })
                this.checkDifference();
            },
            deleteProperty(n){
                this.info_page.properties.splice(n, 1)
                this.checkDifference();
            },
            sendForm(action){
                this.validate(action);
            },

            checkisAdmin(){
                if(!this.user.is_admin){
                    if(this.info_page.properties.length === 0){
                        this.addProperty();
                    }
                }
            },

            saveUser(){
                this.initLoading('Guardando');
                let info_user =  JSON.parse(JSON.stringify(this.user));
                propertyService.createUser(info_user)
                    .then(async (response) => {
                        let user = response.data.data.user;
                        let properties = this.info_page.properties

                        await this.createLog(user._id, 0)
                        //console.log('response', response);
                        if(!user.is_admin){

                            await Promise.all(properties.map(async (item) => {
                                await propertyService.createUserRoles(user._id, item);
                            }));

                            this.showSwal(this.$t('user.new.msg'))
                            this.closeLoading();
                        }else{

                            this.showSwal(this.$t('user.new.msg'))
                            this.closeLoading();
                        }
                    })
                    .catch(error => {

                       let errorReturn = error.response.data.err;
                        if(errorReturn === "User validation failed: email: email debe ser único"){
                            errorReturn = 'El correo electrónico ya ha sido registrado';
                        }
                        this.swalError(errorReturn)
                       this.closeLoading();
                    })
                    .finally(() => {
                    });
            },
            checkDifference(){
                let  selected_properties = this.info_page.properties;
                let full_properties = this.info_page.properties_all;
                full_properties.forEach(item => {
                    item.status = true;
                });

                selected_properties.forEach(item => {
                    //console.log('encontrados',full_properties.findIndex(property => property._id === item._id))
                    let idx = full_properties.findIndex(property => property._id === item.property);
                    if(idx>=0){
                        full_properties[idx].status = false;
                    }

                })



            },
            updateUser(){
                this.initLoading('Guardando');
                let response_user = null;
                let info_user =  JSON.parse(JSON.stringify(this.user));
                propertyService.updateUser(info_user)
                    .then(async (response) => {
                        let user = response.data.data.user;
                        let properties = this.info_page.properties
                        let permissions_old = this.info_page.properties_old;


                        response_user = response.data.data.user;

                        if(!this.user.is_admin){
                            let permissions = [];

                            await Promise.all(properties.map(async (item) => {
                                permissions.push({
                                    sections: item.roles,
                                    property: item.property
                                })
                                let index = permissions_old.indexOf(item.permission_id);
                                (index>=0)?permissions_old.splice(index, 1):'';

                                if(item.permission_id!==''){
                                    await propertyService.updateUserRoles(user._id, item);
                                }else{
                                    await propertyService.createUserRoles(user._id, item);
                                }
                            }));

                            await Promise.all(permissions_old.map(async (permission_id, idx) => {
                                await propertyService.daleteUserRoles(user._id, permission_id);
                            }));

                            //console.log('permissions', permissions)

                            localStorage.setItem('ryp', JSON.stringify(permissions));
                        }






                        let current_user = this.currentUser;
                        let properties_all = this.info_page.properties_all;
                        let properties_Array = [];






                        if(current_user.id===this.user._id){
                            /*this.$store.dispatch('loginUser', response_user).then(() => {
                            })*/
                            let idx_key = 0;
                            console.log(properties_all);
                            while(idx_key<properties_all.length){
                                let item = properties_all[idx_key];
                                let finded = properties.find(elem=> elem.property === item._id)

                                    if(this.user.is_admin){
                                        //console.log('is admin finded', finded);
                                        properties_Array.push({
                                            _id:item._id,
                                            logo:item.logo,
                                            name:item.name,
                                        })
                                    }else if(finded){
                                        //console.log('no is admin', finded);
                                        properties_Array.push({
                                            _id:item._id,
                                            logo:item.logo,
                                            name:item.name,
                                        })
                                    }
                                    idx_key++;
                            }
                          
                           


                            localStorage.setItem('properties', JSON.stringify(properties_Array));
                            EventBus.$emit('RELOAD_PROPERTIES');
                        }

                        let data_response_user  = await propertyService.getUserId(info_user._id);
                        data_response_user = data_response_user.data.data.user;

                        data_response_user.password = '';
                        
                        if(info_user.password!==''){
                            data_response_user.password = '******'
                            this.info_page.original_info.password = '*****'
                        }
                        

                        let changes = logFunctions.cheackProp(this.info_page.original_info, data_response_user, this.info_page.section_name)
                        if(changes.length){
                            await this.createLog(user._id, 1, changes);
                        }

                        this.closeLoading();
                        this.showSwal(this.$t('user.edit.msg'));

                    })
                    .catch(error => {

                       let errorReturn = error.response.data.err;
                        if(errorReturn === "User validation failed: email: email debe ser único"){
                            errorReturn = 'El correo electrónico ya ha sido registrado';
                        }
                        this.swalError(errorReturn)
                       this.closeLoading();
                    })
                    .finally(() => {

                    });
            },
            showSwal (msg) {

                    swal({
                        title: this.$t('user.new.modal'),
                        text: msg,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        type: 'success'
                    }).then(()=> {                        
                        this.$router.push({
                            name: this.ruta
                        });
                    });
            },
            getError (fieldName) {
                return this.errors.first(fieldName)
            },
            validate (action) {

                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        if( this.customValidity()){

                            if(action==='add'){
                                this.saveUser();
                            }else{
                                this.updateUser();
                            }
                        }
                    }

                   //console.log('validado?', isValid)


                    //this.$emit('on-submit', this.registerForm, isValid)
                })
            },
            changeRol(btn, property, rol){
                let value = this.info_page.properties[property].roles[rol];
                let new_valor = 0;

                /*console.log('property:' + value+  ' //rol '+rol);
                console.log('cambiando estado:' + value+  ' //Accion '+btn);*/

                if(value===0)
                    new_valor = 1;
                    if(btn===2)
                       new_valor = 2;
                if(value===1)
                    new_valor = 0;
                    if(btn===2)
                        new_valor = 2;
                if(value===2 && btn === 2)
                    new_valor = 1;

                //console.log('*********Nuevo estado:' + new_valor+  '***************************** ');

                this.$set(this.info_page.properties[property].roles, rol, new_valor);

                return new_valor;
            },
            checkStatus(value, rol){
                let icon;
                if(rol===1)
                    icon = 'fa fa-eye';
                    if(value>=1)
                        icon += ' marked'
                if(rol===2)
                    icon = 'fa fa-pencil';
                    if(value===2)
                        icon += ' marked'


                return icon;
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            swalError(text){
                swal({
                    title: text,
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Modificar',
                    buttonsStyling: false
                }).then((result) => {

                });
            }
        },
        mounted() {
        },
    }

</script>
<style lang="scss">
    label.title-section{
        font-size: 16px;
        color: #313131;
        font-weight: 600;
        margin: 30px 0 20px;
    }
    .form-check{
        .form-check-label{
            &.check-admin{
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    .container-roles{
        width: 100%;
        padding: 0 30px 20px;
        margin-bottom: 25px;
        border-bottom: 2px dashed #f0f0f0;
        .has-label{
            label{
                font-size: 14px;
                font-weight: 600;
            }
        }
        .item-minus{
            float: right;
            line-height: 60px;
            top: 20px;
            position: relative;
            right: 60px;
            font-size: 30px;
            display: block;
        }
        .item-rol{
            padding: 6px !important;
            .box-inside{
                width: 80%;
                a{
                    color: #9A9A9A;
                    float: right;
                    span{
                        text-align: right;
                        width: 30px;
                        font-size: 20px;
                        color: #E1D8E9;
                        &.marked{
                            color: #1FBF64;
                        }
                    }

                }
            }
        }

    }
    .container-footer{
        width: 100%;
        padding: 0 30px;
        text-align: right;

        .btn-plus{
            -webkit-box-shadow: 0 10px 6px -4px #777;
            -moz-box-shadow: 0 10px 6px -4px #777;
            box-shadow: 0 10px 6px -4px #777;
            width: 35px;
            height: 35px;
            font-size: 28px;
            background-color: black;
            display: inline-block;
            border-radius: 100%;
            position: relative;
            right: 45px;
            i{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 4px;
                bottom: 0px;
                vertical-align: middle;
                display: block;
                color: #fff;

            }
        }
    }
    .custom-error{
        label.text-danger{
            text-transform: none;
            white-space: nowrap;

            width: 100%;
            margin-top: 0.25rem;
            font-size: 80%;
            font-weight: 300;
        }
    }
</style>
